import React from "react";
import BasicLayout from "./Layouts/BasicLayout";

const PrivacyPolicy = () => {
  return (
    <BasicLayout>
      <div className="max-w-4xl p-6 mx-auto">
        {/* Title */}
        <h1 className="mb-4 text-3xl font-bold">
          Privacy Policy for Lyde Supplia
        </h1>
        <p className="mb-8 text-gray-600">Last updated: October 2, 2024</p>

        {/* Sections */}
        <div className="space-y-10">
          {/* Introduction */}
          <section className="space-y-2">
            <p className="text-gray-800">
              At Lyde Supplia ("we," "us," or "our"), we are committed to
              safeguarding your privacy and ensuring that your personal
              information is protected when you interact with our services. This
              privacy notice outlines how we collect, store, use, and/or share
              your information when you use our services ("Services"), such as:
            </p>
            <ul className="text-gray-800 list-disc list-inside ms-5">
              <li>
                Visiting our website at{" "}
                <a
                  href="https://www.supplia.fr"
                  className="underline text-primary"
                >
                  https://www.supplia.fr
                </a>
                , or any other website that links to this privacy notice
              </li>
              <li>Engaging with us via sales, marketing, or events</li>
            </ul>
          </section>

          {/* Questions or Concerns */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              Questions or Concerns?
            </h3>
            <p className="text-gray-800">
              If you have any questions or concerns after reading this policy,
              feel free to contact us at{" "}
              <a href="mailto:dpo@supplia.eu" className="font-bold">
                dpo@supplia.eu
              </a>
              . Your privacy is a priority, and understanding this privacy
              notice will help you manage your privacy rights and choices. If
              you do not agree with our policies and practices, please refrain
              from using our Services.
            </p>
          </section>

          {/* Summary of Key Points */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              Summary of Key Points
            </h3>
            <p className="mb-2 text-gray-800">
              This summary provides a quick overview of our privacy practices.
              For more detailed information, feel free to review the full
              privacy policy below.
            </p>
            <ul className="space-y-1 text-gray-800 list-disc list-inside ms-5">
              <li>
                <b>What personal information do we collect? </b> We may collect
                personal information depending on how you interact with our
                Services, such as names, email addresses, job titles, and
                contact preferences. Learn more about the personal information
                you disclose to us
              </li>
              <li>
                <b>Do we process any sensitive personal information? </b> We do
                not process sensitive personal information.
              </li>
              <li>
                <b>Do we collect information from third parties?</b> We do not
                collect information from third parties.
              </li>
              <li>
                <b>How do we use your information? </b>
                We use your information to provide, improve, and administer our
                services.
              </li>
              <li>
                <b>How do we keep your information safe? </b>
                We implement organizational and technical measures to keep your
                information safe.
              </li>
              <li>
                <b>What are your rights? </b>
                Depending on your location, you may have rights concerning your
                personal information.
              </li>
            </ul>
          </section>

          {/* Section 1: What Information Do We Collect? */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              1. What Information Do We Collect?
            </h3>
            <p className="text-gray-800">
              We collect personal information that you voluntarily provide to us
              when you interact with our Services. This may include:
            </p>
            <ul className="space-y-1 text-gray-800 list-disc list-inside ms-5">
              <li>Names</li>
              <li>Email addresses</li>
              <li>Job titles</li>
              <li>Contact preferences</li>
            </ul>
            <p>
              You are responsible for ensuring that the information you provide
              is accurate, complete, and up to date.
            </p>
          </section>

          {/* Section 2: How Do We Process Your Information? */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              2. How Do We Process Your Information?
            </h3>
            <p className="text-gray-800">
              We process your personal information to:
            </p>
            <ul className="text-gray-800 list-disc list-inside ms-5">
              <li>
                Facilitate account creation and manage user authentication.
              </li>
              <li>Provide services and manage user interactions.</li>
              <li>Respond to inquiries and provide support.</li>
              <li>
                Fulfill and manage orders, including payments and returns.
              </li>
              <li>
                Communicate with you about updates, marketing, and promotions.
              </li>
              <li>
                Protect individuals' vital interests (e.g., security measures).
              </li>
            </ul>
          </section>

          {/* Section 3: Legal Bases for Processing */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              3. What Legal Bases Do We Rely on to Process Your Information?
            </h3>
            <p className="text-gray-800">
              If you are located in the EU or UK, the General Data Protection
              Regulation (GDPR) applies. We may process your personal
              information under the following legal bases:
            </p>
            <ul className="text-gray-800 list-disc list-inside ms-5">
              <li>
                <strong>Consent:</strong> With your consent, we may process your
                personal information. You can withdraw your consent at any time.
              </li>
              <li>
                <strong>Performance of a Contract:</strong> We process your
                personal data to fulfill a contract or provide services.
              </li>
              <li>
                <strong>Legal Obligations:</strong> We process your information
                to comply with applicable laws.
              </li>
              <li>
                <strong>Legitimate Interests:</strong> We may process your data
                to pursue legitimate business interests, as long as it doesn’t
                override your rights.
              </li>
            </ul>
          </section>

          {/* Section 4: Sharing Information */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              4. When and With Whom Do We Share Your Information?
            </h3>
            <p className="text-gray-800">
              We may share your personal information in the following
              situations:
            </p>
            <ul className="text-gray-800 list-disc list-inside ms-5">
              <li>
                <strong>Business Transfers:</strong> In connection with any
                merger, acquisition, or sale of assets, we may transfer your
                data.
              </li>
              <li>
                <strong>Third-Party Service Providers:</strong> We may share
                your data with third-party providers to assist in the provision
                of services.
              </li>
            </ul>
          </section>

          {/* Section 5: AI-Driven Services */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              5. Do We Offer Artificial Intelligence-Based Products?
            </h3>
            <p className="text-gray-800">
              Yes, we offer AI-driven products and features within our Services
              to help optimize and automate supply chain management. Our
              AI-based services adhere to strict data protection guidelines,
              ensuring your data is processed securely.
            </p>
          </section>

          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              6. How Long Do We Keep Your Information?
            </h3>
            <p className="text-gray-800">
              We retain your personal information only as long as necessary to
              fulfill the purposes outlined in this privacy notice. When we no
              longer need your information, we will delete or anonymize it.
              However, if legal requirements mandate retention, we will securely
              store the data until deletion is possible.
            </p>
          </section>

          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              7. How Do We Keep Your Information Safe?
            </h3>
            <p className="text-gray-800">
              We implement technical and organizational security measures to
              protect your personal information. However, no electronic system
              is entirely secure, and we cannot guarantee that unauthorized
              parties will not gain access to your data.
            </p>
          </section>

          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              8. Do We Collect Information From Minors?
            </h3>
            <p className="text-gray-800">
              We do not knowingly collect data from children under 18 years old.
              If we become aware that we have inadvertently collected personal
              data from a child under 18, we will promptly delete such
              information.
            </p>
          </section>

          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              9. What Are Your Privacy Rights?
            </h3>
            <p className="text-gray-800">
              If you are based in the European Economic Area (EEA), UK, or other
              regions with applicable privacy laws, you have the following
              rights:
            </p>
            <ul className="text-gray-800 list-disc list-inside ms-5">
              <li>Right to access your personal data.</li>
              <li>Right to correct inaccurate information.</li>
              <li>Right to request the deletion of your personal data.</li>
              <li>Right to withdraw consent for processing at any time.</li>
            </ul>
            <p>
              To exercise your rights, please contact us at{" "}
              <a href="mailto:dpo@supplia.eu" className="text-blue-600">
                dpo@supplia.eu
              </a>
              .
            </p>
          </section>

          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              10. Controls for Do-Not-Track Features
            </h3>
            <p className="text-gray-800">
              Most web browsers offer a Do-Not-Track (DNT) feature. We do not
              currently respond to DNT signals as there is no standardized way
              to process them.
            </p>
          </section>

          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              11. Updates to This Notice
            </h3>
            <p className="text-gray-800">
              We may update this privacy notice to stay compliant with relevant
              laws. When we update the notice, we will change the "last updated"
              date at the top and notify you via email or website notifications.
            </p>
          </section>

          {/* Contact Information */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              12. How Can You Contact Us About This Notice?
            </h3>
            <p className="text-gray-800">
              If you have any questions or concerns about this privacy notice,
              please reach out to our Data Protection Officer (DPO) at{" "}
              <a href="mailto:dpo@supplia.eu" className="text-blue-600">
                dpo@supplia.eu
              </a>{" "}
              or contact us via{" "}
              <a href="mailto:contact@supplia.fr" className="text-blue-600">
                contact@supplia.fr
              </a>
              .
            </p>
          </section>

          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              13. How Can You Review, Update, or Delete Your Information?
            </h3>
            <p className="text-gray-800">
              You may contact us at any time to review, update, or delete your
              personal information by emailing{" "}
              <a href="mailto:dpo@supplia.eu" className="text-blue-600">
                dpo@supplia.eu
              </a>
              . We will consider and act upon your request in accordance with
              applicable laws.
            </p>
          </section>

          <section>
            <p>
              <b> Lyde Supplia</b> Data Protection Officer
            </p>
            <p>
              Email:{" "}
              <a href="mailto:dpo@supplia.eu" className="text-blue-600">
                dpo@supplia.eu
              </a>
            </p>
          </section>
          
          <section>
            <p>
              <b>Lyde Supplia</b> Support
            </p>
            <p>
              Email:{" "}
              <a href="mailto:contact@supplia.fr" className="text-blue-600">
                contact@supplia.fr
              </a>
            </p>
          </section>
        </div>
      </div>
    </BasicLayout>
  );
};

export default PrivacyPolicy;
