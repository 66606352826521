import React from "react";
import { motion } from "framer-motion";
import { TbMapPinSearch, TbSettingsAutomation } from "react-icons/tb";
import { FcProcess } from "react-icons/fc";
import { IoAnalyticsSharp } from "react-icons/io5";
import { GrSystem } from "react-icons/gr";

const FeaturesSection = () => {
  const features = [
    {
      img: "01.png",
      title: "Real-Time Supply Chain Visibility",
      description:
        "Leverages IoT devices and AI analytics for real-time tracking of shipments, inventory, and production processes.",
    },
    {
      img: "02.png",
      title: "Automation of Manual Processes",
      description:
        "Automates critical tasks like order management, procurement, and scheduling to reduce human errors and cut costs.",
    },
    {
      img: "03.png",
      title: "Streamlined Compliance Management",
      description:
        "Ensures seamless compliance with GDPR and other regional regulations, with built-in tools and automated reporting.",
    },
    {
      img: "04.png",
      title: "Predictive Analytics",
      description:
        "Uses AI and machine learning to anticipate delays and supply chain disruptions, offering predictive insights to avoid costly mistakes.",
    },
    {
      img: "05.png",
      title: "Scalable & Modular Architecture",
      description:
        "Allows companies to easily integrate with their existing ERP, CRM, and other enterprise systems.",
    },
  ];

  return (
    <section id="features" className="features">
      <span className="flex justify-center">
        <h1 className="shine">Key Features</h1>
      </span>
      <h3 className="mb-4 text-2xl font-semibold">
        Simplifying Supply Chain Management with Real-Time Intelligence
      </h3>
      <p className="text-gray-500">
        Lyde Supplía is creating the future of supply chain optimization,
        designed for businesses looking to stay ahead in a fast-evolving market.
        We provide seamless integration, real-time visibility, and automation
        that redefine the way you manage operations.
      </p>
      <motion.img
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        initial={{ y: 200 }}
        whileInView={{ y: 0, x: 0 }}
        transition={{ duration: 0.8 }}
        src="Features/main.png"
        alt="Features Section"
        className="m-auto"
      />
      {features.map((feature, index) => (
        <div
          key={index}
          className="grid items-center justify-center text-center lg:grid-cols-2"
        >
          <motion.div
            initial={{ x: index % 2 !== 0 ? 200 : -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            className={index % 2 === 0 ? "order-first" : "lg:order-last"}
          >
            <div className="flex items-center justify-center mb-2 gap-x-2">
              <div className="animate-pulse">
                {feature.img === "01.png" && (
                  <TbMapPinSearch size={35} className="text-primary" />
                )}
                {feature.img === "02.png" && (
                  <TbSettingsAutomation size={35} className="text-primary" />
                )}
                {feature.img === "03.png" && (
                  <FcProcess size={35} className="text-primary" />
                )}
                {feature.img === "04.png" && (
                  <IoAnalyticsSharp size={35} className="text-primary" />
                )}
                {feature.img === "05.png" && (
                  <GrSystem size={35} className="text-primary" />
                )}
              </div>
              <h3 className="mb-1 text-2xl font-semibold">{feature.title}</h3>
            </div>
            <p className="text-gray-500">{feature.description}</p>
          </motion.div>
          <motion.img
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            src={`Features/${feature.img}`}
            width={300}
            alt="Feature-01"
            initial={{ x: index % 2 === 0 ? 200 : -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            className="m-auto"
          />
        </div>
      ))}
    </section>
  );
};

export default FeaturesSection;
