import React from "react";
import Industry from "./Utils/Industry";
import { motion } from "framer-motion";

const IndustriesSection = () => {
  const industries = [
    "Maritime Trade",
    "Port & Terminal Operations",
    "Logistics & Transport",
    "Chemical Manufacturing",
    "Agriculture",
    "Bearing Industry",
    "Lubricant Supply Chain",
  ];
  const handleSayHi = () => {
    const subject = encodeURIComponent("Didn't find what I was looking for");
    const body = encodeURIComponent(
      "Hi Supplía team,\n\nI'm curious to learn more about your offerings. Could you share additional details about your solutions? Looking forward to hearing from you!\n\nBest regards,"
    );
    window.location.href = `mailto:contact@supplia.fr?subject=${subject}&body=${body}`;
  };

  return (
    <section id="industries" className="industries">
      <span className="flex justify-center text-center">
        <h1 className="shine">Industries We Serve</h1>
      </span>
      <h3 className="mb-4 text-2xl font-semibold">Why Choose Lyde Supplía?</h3>
      <p className="text-gray-500">
        Our innovative platform integrates effortlessly with existing systems,
        allowing businesses to modernize without disruption. Whether you're
        looking to optimize logistics or enhance data-driven decision-making,
        Lyde Supplía is built to scale with your business.
      </p>
      <div className="grid mx-20 my-10 lg:grid-cols-4 md:grid-cols-2 gap-y-10">
        {industries.map((industry, index) => (
          <Industry key={index} title={industry} />
        ))}
      </div>
      <div className="items-center justify-center gap-10 mt-16 lg:flex">
        <p>
          Didn't find what you're looking for? Feel free to reach out—there's
          much more under the hood!
        </p>
        <motion.button
          whileHover={{ scale: 1.1 }}
          onClick={handleSayHi}
          className={`font-semibold text-white transition-colors duration-300 bg-purple-600 border-0 rounded-lg shadow-md hover:bg-purple-700`}
        >
          <span>Say Hi!</span>
        </motion.button>
      </div>
    </section>
  );
};

export default IndustriesSection;
