import React from "react";
import { motion } from "framer-motion";
import Slider from "react-infinite-logo-slider";

const AboutSection = () => {
  const handleJoinWaitlist = () => {
    const subject = encodeURIComponent("Join Lyde Supplía Waiting List");
    const body = encodeURIComponent("I would like to join your waiting list.");
    window.location.href = `mailto:contact@supplia.fr?subject=${subject}&body=${body}`;
  };
  const supporters = ["devinc.png", "stationf.svg", "gartner.svg", "aws.png", "toc.webp", "lubricant.png", "bearing.png", "EU-Startups-Logo.png"];

  return (
    <section id="about" className="about">
      <span className="flex justify-center text-center">
        <h1 className="shine">About Lyde Supplía</h1>
      </span>
      <h3 className="mb-4 text-2xl font-semibold">
        Be the First to Experience the Future of Supply Chain
      </h3>
      <p className="mb-5 text-gray-500">
      Experience Lyde Supplía’s game-changing technology—join 
      our early access waitlist and secure your spot for an exclusive demo!
      </p>
      <motion.button
        whileHover={{ scale: 1.1 }}
        onClick={handleJoinWaitlist}
        className={`font-semibold text-white transition-colors duration-300 bg-purple-600 border-0 rounded-lg shadow-md hover:bg-purple-700`}
      >
        <span>Book a Demo</span>
      </motion.button>
      <div className="flex justify-center p-10 overflow-hidden">
        <motion.img
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          initial={{ y: 200 }}
          whileInView={{ y: 0, x: 0 }}
          transition={{ duration: 0.8 }}
          width={500}
          src="About/main.jpg"
          alt="About Section"
          className="m-auto"
        />
      </div>
      <h3 className="mb-4 text-2xl font-semibold">Our Valued Supporters</h3>
      <div className="flex items-center justify-center space-x-10"></div>
      <Slider
        width="250px"
        duration={40}
        pauseOnHover={true}
        blurBorders={false}
        blurBoderColor={"#fff"}
      >
        {supporters.map((supporter, index) => (
          <Slider.Slide key={index}>
            <img width={100} src={`About/${supporter}`} alt="" />
          </Slider.Slide>
        ))}
      </Slider>
    </section>
  );
};

export default AboutSection;
